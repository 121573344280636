<template>
  <div v-loading="loading">
    <div v-if="imageDataUrl" class="counter-form__draw-tool">
      <polygon-draw-tool :image="imageDataUrl" :points="points" @change="(v) => (points = v)" />
    </div>
    <div v-else>
      {{ $tt('cant_load_image') }}
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import axios from 'axios';
import Component from 'vue-class-component';
import PolygonDrawTool from '@/components/polygon-draw/index';

@Component({
  name: 'counter-roi-drawer',
  props: {
    item: {
      type: Object,
      required: true
    },
    cameraId: {
      type: Number,
      required: true
    }
  },
  components: {
    PolygonDrawTool
  }
})
export default class CounterRoiDrawer extends Vue {
  loading = false;
  imageDataUrl = null;

  get points() {
    return (this.item.roi && this.item.roi[this.cameraId]) || [];
  }

  set points(v) {
    if (!this.item.roi) this.item.roi = {};
    if (v && v.length) {
      this.$set(this.item.roi, this.cameraId, v);
    } else {
      this.$delete(this.item.roi, this.cameraId);
    }
  }

  get cameraScreenshotUrl() {
    return this.$store.state.config.server.url + `cameras/${this.cameraId}/screenshot/`;
  }

  mounted() {
    this.loadScreenshot();
  }

  loadScreenshot() {
    if (this.imageDataUrl) return;
    this.loading = true;
    return axios({
      url: this.cameraScreenshotUrl,
      responseType: 'blob',
      headers: {
        Authorization: 'Token ' + encodeURIComponent(this.$store.state.app.token)
      }
    })
      .then((v) => {
        if (v.data && v.data.size) {
          this.imageDataUrl = URL.createObjectURL(v.data);
        } else {
          throw new Error('[image:loaded] empty data error');
        }
      })
      .catch((e) => {
        this.$notify({ duration: 0, message: this.$createElement('message-box', { props: { e: e } }) });
      })
      .finally(() => {
        this.loading = false;
      });
  }
}
</script>
