<template>
  <page-layout>
    <span slot="header-name">{{ countersFormTitle }}</span>
    <common-tabs slot="header-actions" v-model="tab" :items="tabs" :converter="$tfo" class="item-tabs"></common-tabs>
    <div slot="content">
      <el-form
        v-show="tab === 'info'"
        v-loading="loading"
        :size="$vars.sizes.form"
        :label-position="$vars.forms.label_position"
        :label-width="$vars.forms.label_width"
        ref="form"
        :model="item"
        :rules="rules"
        :name="$options.name"
      >
        <el-form-item :label="$tf('id')" v-if="item.id">
          <span name="id">{{ item.id }}</span>
        </el-form-item>

        <el-form-item :label="$tf('name')" prop="name">
          <el-input name="name" v-model="item.name"></el-input>
        </el-form-item>

        <el-form-item prop="camera_groups" :label="$tf('camera_groups')">
          <counters-select-camera-groups
            :value="item.camera_groups"
            :items="$store.getters.cameraGroupsWithoutSpecial"
            @input="handleCameraGroupsSelectInputEvent"
          />
        </el-form-item>

        <el-form-item v-if="item.camera_groups.length" prop="cameras" :label="$tf('cameras')">
          <counters-select-cameras :items="cameras" :value="item.cameras" :groups="item.camera_groups" @input="handleCamerasSelectInputEvent" />
        </el-form-item>

        <el-form-item :label="$tf('counter_interval')" prop="count_interval">
          <el-input name="count_inverval" v-model.number="item.count_interval" class="input--short-number"></el-input>
        </el-form-item>

        <el-form-item prop="detect_faces">
          <el-checkbox name="detect_faces" v-model="item.detect_faces">{{ $tf('detect_faces') }}</el-checkbox>
        </el-form-item>

        <el-form-item prop="detect_silhouettes">
          <el-checkbox name="detect_silhouettes" v-model="item.detect_silhouettes">{{ $tf('detect_bodies') }} </el-checkbox>
          <div class="parameter-description">{{ $tf('parameter.detect_bodies_desc') }}</div>
        </el-form-item>

        <el-form-item prop="detect_cars">
          <el-checkbox name="detect_cars" v-model="item.detect_cars">{{ $tf('detect_cars') }}</el-checkbox>
          <div class="parameter-description">{{ $tf('parameter.detect_cars_desc') }}</div>
        </el-form-item>

        <el-form-item v-if="item.health_status" :label="$tf('common.status')">
          <common-status :color="item.health_status.color" :colorDesc="item.health_status.code_desc"></common-status>
          {{ item.health_status.status | cameraStateFormat }}
          <div class="counter-form__statistic">
            <span>{{ $tf('total | records,,1') }}</span>
            <span>{{ item.health_status.statistic.total_records }}</span>
          </div>
          <div class="counter-form__statistic">
            <span>{{ $tf('failed,,4 | records,,1') }}</span>
            <span>{{ item.health_status.statistic.failed_records }}</span>
          </div>
        </el-form-item>

        <el-form-item prop="active">
          <el-checkbox name="active" v-model="item.active">{{ $tf('common.active') }}</el-checkbox>
          <div class="parameter-description">{{ $tf('active_counters_description') }}</div>
        </el-form-item>

        <template v-if="item.detect_silhouettes">
          <el-form-item prop="detect_proximity">
            <el-checkbox name="detect_proximity" v-model="item.detect_proximity">{{ $tf('common.distance_measurement') }} </el-checkbox>
            <div class="parameter-description">{{ $tf('parameter.distance_measurement_desc') }}</div>
          </el-form-item>

          <el-form-item :label="$tf('status')">
            <el-tooltip effect="dark" :disabled="!item.proximity_calibration_error" :content="item.proximity_calibration_error" placement="right">
              <span name="proximity_calibration_status">{{ translatedCalibrationStatus }}</span>
            </el-tooltip>
          </el-form-item>

          <el-form-item>
            <el-input
              name="collect_track_data_seconds"
              v-model.number="item.collect_track_data_seconds"
              class="input--short-number"
              :disabled="!detectProximityEnabled"
            ></el-input>

            <el-button
              name="start_calibration-btn"
              type="primary"
              @click="startCalibrationHandler"
              class="start_calibration-btn"
              :disabled="!startCalibrationEnabled"
              >{{ $tf('calibrate_counter') }}
            </el-button>
            <div class="parameter-description">{{ $tf('collect_track_data_seconds') }}</div>
            <div class="parameter-description">{{ $tf('parameter.collect_track_data_seconds_desc') }}</div>
          </el-form-item>

          <el-button name="stop_calibration-btn" type="info" @click="stopCalibrationHandler" v-if="stopCalibrationDisplay"
            >{{ $tf('stop_calibration') }}
          </el-button>

          <el-dialog :modal="true" :title="$tf('parameter.detect_proximity_recommendations_desc')" :visible.sync="detectProximityDialogDisplay">
            <ol>
              <li>{{ $tf('parameter.detect_proximity_recommendations_desc_1') }}</li>
              <li>{{ $tf('parameter.detect_proximity_recommendations_desc_2') }}</li>
              <li>{{ $tf('parameter.detect_proximity_recommendations_desc_3') }}</li>
            </ol>
            <el-button type="primary" @click="detectProximityDialogDisplay = false">{{ $tf('ok') }}</el-button>
          </el-dialog>
        </template>
      </el-form>

      <!-- ROI -->
      <div v-if="tab === 'roi'">
        <counters-roi :item="item" :cameras-map="camerasMap" />
      </div>

      <!-- Chart -->
      <div v-if="tab === 'chart'">
        <el-form :label-position="$vars.forms.label_position" :labelWidth="$vars.forms.label_width">
          <el-form-item :label="$tf('interval')">
            <el-radio-group v-model="timeInterval" class="mar-bottom--2">
              <el-radio-button label="hour">{{ $tf('hour') }}</el-radio-button>
              <el-radio-button label="day">{{ $tf('day') }}</el-radio-button>
              <el-radio-button label="week">{{ $tf('week') }}</el-radio-button>
            </el-radio-group>
          </el-form-item>
        </el-form>

        <div class="counter-chart">
          <counter-chart-item
            :item="item"
            :dispose-before-update="true"
            :grid="true"
            :live="false"
            :filter="chartFilter"
            :click-handler="chartClickHandler"
          ></counter-chart-item>
        </div>
      </div>

      <!-- Buttons -->
      <div class="mar-top--large">
        <el-button
          v-if="tab !== 'chart'"
          name="save-btn"
          type="primary"
          @click="saveHandler"
          :disabled="$hasNoPermission('ffsecurity.change_counter') || !enabledSave"
          >{{ $tf('common.save') }}
        </el-button>
        <el-button name="back-btn" type="info" :plain="true" @click="cancelHandler">{{ $tf('common.back') }}</el-button>
      </div>

      <div class="mar-top--large">
        <confirm-button
          :header="$tf('delete_confirm | counter') + '?'"
          size="mini"
          plain
          @confirm="deleteHandler"
          :disabled="$hasNoPermission('ffsecurity.delete_counter')"
          v-if="!create"
        >
        </confirm-button>
      </div>
    </div>
  </page-layout>
</template>

<script>
import _ from '@/apps/common/lodash';
import { getNumberValidator } from '@/apps/common/validator';
import difference from 'lodash/difference';
import uniq from 'lodash/uniq';
import PolygonDrawTool from '@/components/polygon-draw/index';
import CameraScreenshot from '../cameras/camera-screenshot';
import ConfirmButton from '../confirm/confirm-button';
import ChartArea from './chart.area';
import CounterChartItem from './counter.chart.item';
import CountersRoi from './form/roi.form';
import CountersSelectCameraGroups from './counters-select-camera-groups';
import CountersSelectCameras from './counters-select-cameras';
import PageLayout from '@/components/page/layout';
import { CalibrationStatuses, CalibrationStatusesI18n } from './counters.constants';
import CommonStatus from '@/components/common/common-status';

const TimeIntervalToHours = {
  hour: 1,
  day: 24,
  week: 24 * 7
};

const baseRules = {
    name: [{ required: true, message: 'error.required.field_name', trigger: 'change', min: 2 }],
    camera_groups: [{ required: true, message: 'error.required.field', type: 'array', min: 1, trigger: 'change' }],
    cameras: [{ required: true, message: 'error.required.field', type: 'array', min: 1, trigger: 'change' }],
    count_interval: [
      {
        required: true,
        tmessage: 'error.field.uint_max_min',
        trigger: 'change',
        validator: getNumberValidator({ required: true, min: 1, max: 36000 })
      }
    ],
    collect_track_data_seconds: [
      {
        required: false,
        tmessage: 'error.field.uint_max_min',
        trigger: 'change',
        validator: getNumberValidator({ required: false, min: 15, max: 300 })
      }
    ]
  },
  syncRules = {
    remote_url: [{ required: true, message: 'error.required.field', trigger: 'change' }],
    remote_dossier_list: [{ required: true, message: 'error.required.field', trigger: 'change' }]
  },
  emptySyncItem = { remote_url: '', slave_dossier_list: null, remote_dossier_list: null };

const CameraGroupsChange = Object.freeze({
  Extended: 'selected',
  Reduced: 'Unselected'
});

export default {
  name: 'counter-form',
  components: {
    PolygonDrawTool,
    CounterChartItem,
    ChartArea,
    CameraScreenshot,
    ConfirmButton,
    CountersSelectCameras,
    CountersSelectCameraGroups,
    CountersRoi,
    PageLayout,
    CommonStatus
  },
  data: function () {
    return {
      formContent: {
        image: null
      },
      tab: 'info',
      timeInterval: 'hour',
      rules: this.$applyRuleMessages(baseRules),
      syncRules: this.$applyRuleMessages(syncRules),
      create: true,
      item: {},
      itemCopy: {},
      loading: false,
      saving: false,
      syncDialog: false,
      syncItem: _.cloneDeep(emptySyncItem),
      detectProximityDialogDisplay: false,
      statusInterval: '',
      calibrationPending: false
    };
  },
  computed: {
    enabledSave() {
      const item = { ...this.item, health_status: null };
      const itemCopy = { ...this.itemCopy, health_status: null };
      const diff = _.differenceOf(item, itemCopy);
      return !!Object.keys(diff).length && !this.saving;
    },
    detectProximityEnabled() {
      return !this.create && this.item.detect_silhouettes && this.item.detect_proximity && this.$hasPermission('ffsecurity.change_counter');
    },
    stopCalibrationDisplay() {
      return !this.notCalibrated && this.detectProximityEnabled;
    },
    startCalibrationEnabled() {
      return !this.calibrationIsRunning && this.detectProximityEnabled && !this.calibrationPending && this.item.collect_track_data_seconds;
    },
    notCalibrated() {
      return this.calibrationStatus === CalibrationStatuses.NOT_CALIBRATED;
    },
    calibrationIsRunning() {
      return this.calibrationStatus !== CalibrationStatuses.NOT_CALIBRATED && this.calibrationStatus !== CalibrationStatuses.CALIBRATED;
    },
    calibrationStatus() {
      return CalibrationStatuses[this.item.proximity_calibration_status] || CalibrationStatuses.NOT_CALIBRATED;
    },
    translatedCalibrationStatus() {
      return this.$tf(CalibrationStatusesI18n[this.calibrationStatus]);
    },
    countersFormTitle() {
      return this.create ? this.$tf('create_counter') : this.$tf('edit_counter');
    },
    chartFilter() {
      const hours = TimeIntervalToHours[this.timeInterval];
      return { created_date_gte: new Date(Date.now() - 36 * 1e5 * hours), limit: 1e4 };
    },
    tabs() {
      return this.item.id ? [{ name: 'info' }, { name: 'roi' }, { name: 'chart' }] : [{ name: 'info' }];
    },
    state() {
      return this.$store.state.counters;
    },
    cameras() {
      return this.$store.state.cameras.items;
    },
    camerasMap() {
      return this.$store.state.cameras.items.reduce(mapCameraIdToCamera, {});
    }
  },
  watch: {
    calibrationStatus: (v) => {
      if (v === CalibrationStatuses.NOT_CALIBRATED) {
        clearInterval();
      }
    }
  },
  created() {
    this.item = _.cloneDeep(this.state.item.empty);
    this.itemCopy = _.cloneDeep(this.item);
    this.load();
    this.$store.dispatch(this.$store.state.groups.Action.Get);
  },
  beforeDestroy() {
    clearInterval(this.statusInterval);
  },
  methods: {
    setIntervalForCalibrationStatus() {
      this.statusInterval = setInterval(
        () => {
          this.updateCalibrationStatus();
        },
        5000,
        true
      );
    },
    isDisabled() {
      return this.item.id === -1;
    },
    load() {
      let id = this.$route.params.id;
      this.create = !id;
      if (id) {
        this.loading = true;
        this.$store
          .dispatch(this.state.Action.Get, { id: decodeURIComponent(id) })
          .then((v) => {
            this.item = v;
            this.itemCopy = _.cloneDeep(this.item);
          })
          .catch((e) => {
            this.$notify({ duration: 0, message: this.$createElement('message-box', { props: { e } }) });
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    updateCalibrationStatus() {
      const id = this.$route.params.id;
      if (id) {
        this.$store.dispatch(this.state.Action.Get, { id: decodeURIComponent(id) }).then((v) => {
          this.item.proximity_calibration_status = v.proximity_calibration_status;
        });
      }
    },
    saveHandler() {
      if (this.$refs.roi) this.$refs.roi.syncToInstance();
      this.$refs.form.validate(this.validateHandler);
    },
    validateHandler(valid) {
      if (!valid) return;
      this.save(this.item);
    },
    save(item) {
      let action = this.create ? this.state.Action.Create : this.state.Action.Update;
      this.saving = true;
      this.$store
        .dispatch(action, item)
        .then((v) => {
          this.item = v;
          this.itemCopy = _.cloneDeep(this.item);
          this.create = false;
          this.$notify({ type: 'success', message: this.$tf('action | success') });
          this.$router.replace({ path: `/counters/${v.id}/` });
        })
        .catch((e) => {
          this.$notify({ duration: 0, message: this.$createElement('message-box', { props: { e: e } }) });
        })
        .finally(() => {
          this.saving = false;
        });
    },
    deleteHandler() {
      this.$store
        .dispatch(this.state.Action.Delete, this.item)
        .then(() => {
          this.$notify({ type: 'success', message: this.$tf('action | success') });
          this.$router.push({ path: '/counters/filter/limit=20' });
        })
        .catch((e) => {
          this.$notify({ duration: 0, message: this.$createElement('message-box', { props: { e: e } }) });
        });
    },
    startCalibrationHandler() {
      this.calibrationPending = true;
      this.detectProximityDialogDisplay = true;
      this.$store
        .dispatch(this.state.Action.StartCalibration, { ...this.item })
        .then((v) => {
          this.$notify({ type: 'success', message: this.$tf('action | success') });
          this.$store.dispatch(this.$store.state.groups.Action.Get);
          this.setIntervalForCalibrationStatus();
        })
        .catch((e) => {
          this.calibrationPending = false;
          this.$notify({ duration: 0, message: this.$createElement('message-box', { props: { e: e } }) });
        });
    },
    stopCalibrationHandler() {
      this.calibrationPending = false;
      this.$store
        .dispatch(this.state.Action.StopCalibration, this.item)
        .then((v) => {
          this.$notify({ type: 'success', message: this.$tf('action | success') });
          this.$store.dispatch(this.$store.state.groups.Action.Get);
          this.updateCalibrationStatus();
          clearInterval(this.statusInterval);
        })
        .catch((e) => {
          this.$notify({ duration: 0, message: this.$createElement('message-box', { props: { e: e } }) });
        });
    },
    cancelHandler() {
      this.$router.backTo({ path: '/counters/filter/limit=20' });
    },
    chartClickHandler(v) {
      this.showImage(v);
    },
    showImage(v) {
      const record = v.records?.find((item) => !!item.fullframe);
      if (record.length) return;

      const imageData = {
        src: record.fullframe,
        faces_bbox: this.getBBoxesFromArray(record.faces_bbox),
        cars_bbox: this.getBBoxesFromArray(record.cars_bbox),
        silhouettes_bbox: this.getBBoxesFromArray(record.silhouettes_bbox)
      };

      this.$store.dispatch('showImage', imageData);
    },
    getBBoxesFromArray(v) {
      return (v || []).map((i) => this.$filters.polyArrayToShortRect(i));
    },
    handleCameraGroupsSelectInputEvent(groups) {
      const changes = this.computeCameraGroupsChanges(groups);
      this.item.cameras = this.mapCameraGroupsChangesToCameraList(changes);
      this.item.camera_groups = groups;
    },
    handleCamerasSelectInputEvent(cameras) {
      const groupId = this.computeRemovedCameraGroup(cameras);
      if (groupId !== null) {
        this.item.camera_groups = this.item.camera_groups.filter((id) => id !== groupId);
      }
      this.item.cameras = cameras;
    },
    getCameraGroupId(cameraId) {
      return this.camerasMap[cameraId].group;
    },
    computeCameraGroupsChanges(groups) {
      const curr = groups;
      const prev = this.item.camera_groups;
      return curr.length > prev.length ? [CameraGroupsChange.Extended, difference(curr, prev)] : [CameraGroupsChange.Reduced, difference(prev, curr)];
    },
    mapCameraGroupsChangesToCameraList([change, groups]) {
      return change === CameraGroupsChange.Extended ? this.appendCameraIdsFromAddedCameraGroups(groups) : this.removeCameraIdsFromRemovedCameraGroups(groups);
    },
    appendCameraIdsFromAddedCameraGroups(groups) {
      return this.item.cameras.concat(this.cameras.filter(({ group }) => groups.includes(group)).map(({ id }) => id));
    },
    removeCameraIdsFromRemovedCameraGroups(groups) {
      return this.item.cameras.filter((id) => !groups.includes(this.getCameraGroupId(id)));
    },
    computeRemovedCameraGroup(cameras) {
      const curr = this.item.camera_groups;
      const next = uniq(cameras.map(this.getCameraGroupId));
      if (next.length < curr.length) {
        return difference(this.item.camera_groups, next)[0];
      }
      return null;
    }
  }
};

function mapCameraIdToCamera(camerasMap, camera) {
  return _.set(camerasMap, camera.id, camera);
}
</script>

<style lang="stylus">
.counter-chart {
  position: relative;
  max-width: 64rem;
  height: 480px;
}

.counter-form__draw-tool {
  width: 800px;
  height: 600px;
}

.counter-form__statistic {
  display: flex;
  justify-content: space-between;
  width: 50%;
  &:first-of-type {
    margin-top: 30px;
  }
}

.start_calibration-btn {
  margin: 0 0 0.7rem 1rem;
}
</style>
